<template>
  <base-form
    v-if="editItem"
    :editStatus="true"
    :editItem="editItem"
    @update="handleSubmit"
    @cancel="handleCancel"
  />
</template>

<script>

  import { mapGetters, mapActions } from 'vuex';
  import dialogs from '@/services/dialogs.js';
  import { getLocalMessage } from '@/utils';

  export default {
    components: {
      BaseForm: () => import('./BaseForm')
    },
    props: {
      id: {
        type: String,
        default: () => null
      }
    },
    data () {
      return {
        editItem: null
      }
    },

    computed: {
      ...mapGetters({
        currentUser: 'user/currentUser'
      })
    },

    created () {
      if (this.id) {
        this.userShow( this.id ).then( () => {
          this.editItem = this.currentUser
        } )
      }
    },

    methods: {
      ...mapActions({
        userShow: 'user/show',
        userUpdate: 'user/update'
      }),

      getLocalMessage,

      handleSubmit (data) {
        this.userUpdate(data).then(() => {
          this.$router.push({name: 'systemUser'})
        }).catch( error => {
          if (error.data && error.data.code == 1100) {
            let messages = error.data.message.split('\'')
            console.log(messages)
            dialogs.message(this.$vuetify.lang.t('$vuetify.message.resourceExistedErr', [this.getLocalMessage(this, 'user', messages[1])]), { duration: 10, type: 'error' })
          } else {
            dialogs.message(this.$vuetify.lang.t('$vuetify.message.systemErr'), { duration: 10, type: 'error' })
          }
        })
      },

      handleCancel() {
        this.$router.go(-1)
      }
    }
  }
</script>
