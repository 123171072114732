export function listToTree (list, options) {
  var map = {}, node, roots = [], i;

  for (i = 0; i < list.length; i += 1) {
    map[list[i].id] = i; // initialize the map
    list[i].children = []; // initialize the children
    for (let o in options) {
      list[i][o] = list[i][options[o]]
    }
  }

  for (i = 0; i < list.length; i += 1) {
    node = list[i];
    if (node.parentId !== "0" && node.parentId !== null) {
      // if you have dangling branches check that map[node.parentId] exists
      list[map[node.parentId]].children.push(node);
    } else {
      roots.push(node);
    }
  }
  return roots;
}


export function snakeToCamel (str) {
  return str.replace(
    /([-_][a-z])/g,
    (group) => group.toUpperCase()
                    .replace('-', '')
                    .replace('_', '')
  )
}


export function getLocalMessage (obj, modelName, name) {
  let res = obj.$vuetify.lang.t(`$vuetify.${modelName}.${name}`)
  if (res && res.indexOf('$vuetify') === -1) {
    return res
  }
  res = obj.$vuetify.lang.t(`$vuetify.common.${name}`)
  if (res && res.indexOf('$vuetify') === -1) {
    return obj.$vuetify.lang.t(`$vuetify.common.${name}`)
  }
  return name
}
